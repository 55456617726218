import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, EmailBanner } from '../components'
import { HTMLContent, AboutPageTemplate } from '../../../shared'

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data
  const {
    html,
    frontmatter: { aboutImage: image },
  } = post

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        content={html}
        image={image}
        EmailBanner={EmailBanner}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        aboutImage {
          childImageSharp {
            gatsbyImageData(width: 1300, quality: 100)
          }
        }
      }
    }
  }
`
